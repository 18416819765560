import './Footer.css';

export default function SearchBar() {
  return (
    <div className="footer">
      <span>© 2023 RibbonMD, Inc. </span>
      <span className="right">
        <a href="https://ribbonmd.com/terms" target="_blank" rel="noreferrer">
          Terms & Conditions{' '}
        </a>
        ·
        <a href="https://ribbonmd.com/hipaa" target="_blank" rel="noreferrer">
          HIPAA Compliance{' '}
        </a>
        ·
        <a href="https://ribbonmd.com/privacy" target="_blank" rel="noreferrer">
          Privacy Policy{' '}
        </a>
      </span>
    </div>
  );
}
