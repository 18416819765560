import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/Context';

function RequireAuth({ children }) {
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();
  const navigate = useNavigate();
  const token = auth.getToken();
  const user = auth.getUser();

  useEffect(() => {
    const isTokenExpired = (token) => {
      const currentTime = Date.now() / 1000;
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      return decodedToken.exp < currentTime;
    };

    if (!token) {
      navigate('/signup', { replace: true });
    }

    if (token && isTokenExpired(token)) {
      navigate('/signup', { replace: true });
    }

    if (user && user.payment === 0) {
      navigate('/billing', { replace: true });
    }
  }, [token, user, navigate]);

  return children;
}

export default RequireAuth;
