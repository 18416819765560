import { useRef, useContext } from 'react';
import { useLoaderData } from 'react-router-dom';
import {
  ScheduleComponent,
  Day,
  Week,
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
} from '@syncfusion/ej2-react-schedule';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { createElement } from '@syncfusion/ej2-base';

import { AuthContext } from '../utils/Context';
import {
  addAppointment,
  editAppointment,
  deleteAppointment
} from '../utils/API';

export default function Calendar() {
  const scheduleObj = useRef(null);
  const { providers, patients, appointmentData } = useLoaderData();
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();
  const user = auth.getUser();
  const userTypeId = user.userTypeId;

  const colors = [
    '#2e7e7e',
    '#3b82f6',
    '#f0c419',
    '#7f88b6',
    '#ef5d60',
    '#4dd184',
    '#fbcfbd',
    '#ce596d',
  ];

  const locationData = user.practice.locations;
  const patientData = patients.map(patient => {
    return {
      patientName: `${patient.patientFname} ${patient.patientLname}`,
      patientId: patient.patientId,
    }
  });

  const mapColorsToEmployeesByGroupId = (employeeData, colors) => {
    // Extract unique GroupIds from employeeData
    const uniqueGroupIds = [
      ...new Set(providers.map((employee) => employee.GroupId)),
    ];

    // Create a mapping of GroupId to color
    const groupColorMap = uniqueGroupIds.reduce((acc, groupId, index) => {
      acc[groupId] = colors[index % colors.length]; // Loop through colors if there are more groupIds than colors
      return acc;
    }, {});

    // Add color property to each employee based on their GroupId
    return providers.map((employee) => {
      return { ...employee, Color: groupColorMap[employee.GroupId] };
    });
  }

  const employeeData = mapColorsToEmployeesByGroupId(providers, colors);
  const fieldsData = {
    id: 'Id',
    description: { name: 'Description', title: 'Chief Complaint' },
    startTime: { name: 'StartTime', title: 'Start' },
    endTime: { name: 'EndTime', title: 'End' },
  };

  const saveAppointment = async appointment => {
    await addAppointment(appointment);
  };

  const updateAppointment = async appointment => {
    await editAppointment(appointment);
  }

  const getEmployeeName = value => {
    return value.resourceData[value.resource.textField];
  };

  // const getEmployeeImage = value => {
  //   return getEmployeeName(value).toLowerCase();
  // };

  const getEmployeeDesignation = value => {
    return value.resourceData.Designation;
  };

  const resourceHeaderTemplate = props => {
    return (
      <div className="template-wrap">
        <div className="employee-category">
          {/* <div className={'employee-image ' + getEmployeeImage(props)} /> */}
          <div className="employee-name"> {getEmployeeName(props)}</div>
          <div className="employee-designation">
            {getEmployeeDesignation(props)}
          </div>
        </div>
      </div>
    );
  };

    const template = props => {
      return (
        <div className="tooltip-wrap">
          <div className="content-area">
            <div className="event-name">Patient: {props.Subject}</div><br />
            <div>
              From&nbsp;:&nbsp;{props.StartTime.toLocaleString()}
            </div>
            <div>
              To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;
              {props.EndTime.toLocaleString()}
            </div><br />
            {props.Description ? (
              <div>Description: {props.Description}</div>
            ) : (
              ''
            )}
          </div>
        </div>
      );
    };

  const handleActionComplete = actionComplete => {
    if (actionComplete.requestType === 'eventChanged') {
      const updatedEvent = actionComplete.changedRecords[0];
      updatedEvent.Subject = patientData.filter(item => item.patientId === updatedEvent.patientId)[0].patientName;
      updateAppointment(updatedEvent);
    } else if (actionComplete.requestType === 'eventCreated') {
      const newEvent = actionComplete.addedRecords[0];
      newEvent.Subject = patientData.filter(item => item.patientId === newEvent.patientId)[0].patientName;
      saveAppointment(newEvent);
    } else if (actionComplete.requestType === 'eventRemoved') {
      const deletedAppointment = actionComplete.deletedRecords[0];
      deleteAppointment(deletedAppointment);
    }
  };

  const onPopupOpen = args => {
    if (args.type === 'Editor') {
      // Create required custom elements only on initial rendering
      if (!args.element.querySelector('.custom-field-row')) {
        // Custom Location Field
        let row = createElement('div', { className: 'custom-field-row' });
        let formElement = args.element.querySelector('.e-schedule-form');
        formElement.firstChild.insertBefore(
          row,
          formElement.firstChild.firstChild
        );
        let container = createElement('div', {
          className: 'custom-field-container',
        });
        let inputEle = createElement('input', {
          className: 'e-field',
          attrs: { name: 'LocationId' },
        });
        container.appendChild(inputEle);
        row.appendChild(container);
        let dropDownList = new DropDownList({
          dataSource: locationData,
          fields: { text: 'locationName', value: 'locationId' },
          value: args.data.locationId,
          floatLabelType: 'Always',
          placeholder: 'Location',
        });
        dropDownList.appendTo(inputEle);
        inputEle.setAttribute('name', 'locationId');
      }

      if (!args.element.querySelector('.custom-patients')) {
        // Custom Patient Field
        let row = createElement('div', { className: 'custom-patients' });
        let formElement = args.element.querySelector('.e-schedule-form');
        formElement.firstChild.insertBefore(
          row,
          formElement.firstChild.firstChild
        );
        let container = createElement('div', {
          className: 'custom-field-container',
        });
        let inputEle = createElement('input', {
          className: 'e-field',
          attrs: { name: 'PatientId' },
        });
        container.appendChild(inputEle);
        row.appendChild(container);
        let dropDownList = new DropDownList({
          dataSource: patientData,
          fields: { text: 'patientName', value: 'patientId' },
          value: args.data.patientId,
          floatLabelType: 'Always',
          placeholder: 'Patient',
        });
        dropDownList.appendTo(inputEle);
        inputEle.setAttribute('name', 'patientId');
      }
    }
  };

  const viewMode = userTypeId === 3 ? 'Agenda' : 'Day';
  const readOnlyMode = userTypeId === 3 ? true : false;
  const eventSettings = userTypeId === 3 ? {
    dataSource: appointmentData,
    fields: fieldsData,
    enableTooltip: true,
    tooltipTemplate: template,
  } : {
    dataSource: appointmentData,
    fields: fieldsData
  };
  const injectableServices = userTypeId === 3
    ? [Day, Week, Agenda]
    : [Day, Week, Agenda, DragAndDrop, Resize];
  const renderViewDirectives = () => {
    return (
      <ViewsDirective>
        <ViewDirective option="Day" startHour="7:00" endHour="20:00" />
        <ViewDirective option="Week" startHour="7:00" endHour="20:00" />
        <ViewDirective option="Agenda" />
      </ViewsDirective>
    );
  }

  return (
    <div className="content">
      <ScheduleComponent
        cssClass="block-events"
        width="100%"
        height="96%"
        currentView={viewMode}
        readonly={readOnlyMode}
        resourceHeaderTemplate={resourceHeaderTemplate}
        eventSettings={eventSettings}
        group={{ enableCompactView: false, resources: ['Employee'] }}
        actionComplete={handleActionComplete}
        ref={scheduleObj}
        popupOpen={onPopupOpen}
        showQuickInfo={false}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="EmployeeId"
            title="Provider"
            name="Employee"
            allowMultiple={false}
            dataSource={employeeData}
            textField="Text"
            idField="Id"
            colorField="Color"
          />
        </ResourcesDirective>
        {renderViewDirectives()}
        <Inject services={injectableServices} />
      </ScheduleComponent>
    </div>
  );
}
