import axios from 'axios';
// import mixpanel from 'mixpanel-browser';

const getAuthToken = () => `Bearer ${sessionStorage.getItem('token')}`;
// const subscriptionKey = process.env.REACT_APP_MIXPANEL_KEY;

// mixpanel.init(subscriptionKey, { debug: true });

// mixpanel.identify(getAuthToken());

// mixpanel.track('Signed Up', {
//   'Signup Type': 'Referral'
// })


// on 401s redirect to login page
const handleAuthError = (error, callback) => {
  if (error.message.length >= 3 && error.message.slice(-3) === '401') {
    window.alert('Unauthorized');
    callback();
  }
}

const baseURL = process.env.REACT_APP_IS_PROD === 'true'
  ? 'https://ribbon-api-prod.azurewebsites.net/api/'
  : 'https://ribbon-api-dev.azurewebsites.net/api/';


// set base url and headers
const API = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// intercept requests and add authorization header
API.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getAuthToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getPatients = () =>
  API.get('patients')
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    }
  );

const addPatient = (patient) =>
  API.post('patients', patient)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    }
  );

const addNote = (note) =>
  API.post('Visit', note)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });


const updateNote = (visit) =>
  API.post('Visit/update', visit)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    }
  );

const updateSoapNote = (visit) =>
  API.post('Visit/updatesoap', visit)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    }
  );

const endAppt = (conversation) =>
  API.post('Visit/end', conversation)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

const getPatientRibbon = (id) =>
  API.get(`Visit/ribbon/${id}`)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw new Error(err);
    }
  );


const getNoteDetails = (id) =>
  API.get(`Visit/notes/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    }
  );


const sendFeedback = (feedback) =>
  API.post('feedback', feedback)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    }
  );

const getAppointments = () =>
  API.get('Appointment')
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    }
  );

const addAppointment = (appointment) =>
  API.post('Appointment/add', appointment)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    }
  );

const editAppointment = (appointment) =>
  API.post('Appointment/edit', appointment)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

const deleteAppointment = (appointment) =>
  API.post('Appointment/delete', appointment)
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });

const getProviders = () =>
  API.get('appointment/providers')
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    }
  );

export {
  getPatients,
  addPatient,
  handleAuthError,
  addNote,
  getPatientRibbon,
  getNoteDetails,
  sendFeedback,
  updateNote,
  updateSoapNote,
  endAppt,
  getAppointments,
  addAppointment,
  editAppointment,
  deleteAppointment,
  getProviders,
};
