import { useRouteError } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';

function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  const bugsnagErr = new Error(JSON.stringify(error));
  Bugsnag.notify(bugsnagErr);
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{ error.status } {error.statusText}</i>
      </p>
      <span>{ error.message || null }</span>
    </div>
  );
}

export default ErrorPage;
