import { useState, useEffect, useRef, Fragment } from 'react';
import { utcToZonedTime, format } from 'date-fns-tz';
import SpeechToText from '../SpeechToText/SpeechToText';
import Timeline from '../Timeline/Timeline';
import TimelineItem from '../Timeline/TimelineItem';
import Loader from '../Loader/Loader';
import { urlRegex } from '../../utils/regex';
import { notifyError, notifySuccess } from '../../utils/Toast';
import { getNoteDetails, updateSoapNote } from '../../utils/API';
import './PatientDetails.css';

export default function PatientDetails({ patient, history, getUpdatedPatientHistory }) {
  const [notesDetails, setNotesDetails] = useState({});
  const [editNoteData, setEditNoteData] = useState(null);
  const [ribbonItems, setRibbonItems] = useState([]);
  const [showReferences, setShowReferences] = useState(false);
  const [showDischargeSummary, setShowDischargeSummary] = useState(false);
  const [showDischargeInstructions, setShowDischargeInstructions] = useState(false);
  const [showCodes, setShowCodes] = useState(false);
  const [showSuperbill, setShowSuperbill] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const timelineRef = useRef(null);

  const visitSummaryIsLoading = (summary) => {
    return summary === 'RibbonMD AI is generating summary... beep beep boop !';
  };

  useEffect(() => {
    if (history[0] && history[0].visitSummary && visitSummaryIsLoading(history[0].visitSummary)) {
      const fetchData = async () => {
        const newHistory = await getUpdatedPatientHistory(patient.patientId);
        return newHistory;
      };

      setTimeout(() => {
        const newHistory = fetchData();
        if (
          newHistory &&
          Array.isArray(newHistory) &&
          !visitSummaryIsLoading(newHistory[0].visitSummary)
        ) {
          setRibbonItems(newHistory);
        }
      }, 60000);
    }
    setShowCodes(false);
    setShowSuperbill(false);
    setShowReferences(false);
    setShowDischargeSummary(false);
    setShowDischargeInstructions(false);
    setNotesDetails({});
    setRibbonItems(history);
  }, [history, getUpdatedPatientHistory, patient]);

  const getVisitDetails = async (id) => {
    try {
      const visitDetails = await getNoteDetails(id);
      setNotesDetails({
        id,
        soap: visitDetails.visitSoapNote,
        subjective: visitDetails.visitSubjective,
        objective: visitDetails.visitObjective,
        assessment: visitDetails.visitAssessment,
        plan: visitDetails.visitPlan,
        superbill: visitDetails.visitSuperbill,
        codes: visitDetails.visitIcdCodes,
        reference: visitDetails.visitReference,
        dischargeSummary: visitDetails.visitDischargeSummary,
        dischargeInstructions: visitDetails.visitDischargeInstructions,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const editCallback = id => {
    getVisitDetails(id);
    setEditNoteData(null);
  };

  const copyToClipboard = async (item) => {
    let text = notesDetails.soap;
    if (item) text = item;
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const addItemToRibbon = (newNote) => {
    let date = new Date();
    date.setHours(date.getHours() + 4);
    const newRibbonItem = {
      visitDate: date,
      visitId: newNote.visitId || date, // date is used as a placeholder for new notes
      triageBpm: newNote.triageBpm,
      triageDiastolic: newNote.triageDiastolic,
      triageSystolic: newNote.triageSystolic,
      triageTemp: newNote.triageTemp,
      visitSummary: 'RibbonMD AI is generating summary... beep beep boop !',
      subjective: newNote.visitSubjective,
      objective: newNote.visitObjective,
      assessment: newNote.visitAssessment,
      soap: newNote.visitSoapNote,
      plan: newNote.visitPlan,
    };
    setNotesDetails({
      id: newNote.visitId || date,
      subjective: newNote.visitSubjective,
      objective: newNote.visitObjective,
      assessment: newNote.visitAssessment,
      plan: newNote.visitPlan,
      soap: newNote.visitSoapNote,
    });
    setRibbonItems([
      newRibbonItem,
      ...ribbonItems
    ]);

    setTimeout(() => {
      const newHistory = getUpdatedPatientHistory(patient.patientId);
      setRibbonItems(newHistory);
    }, 3000);
  };

  const editNote = async (note) => {
    let fullNoteData = note;
    try {
      const visitDetails = await getNoteDetails(note.visitId);
      fullNoteData = Object.assign(fullNoteData, visitDetails);
      setEditNoteData(fullNoteData);
      timelineRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      throw new Error(error);
    }
    setShowEditModal(true);
  };

  const editModal = () => {
    return (
      <div className="modal">
        <div className="modal-content">
          <form>
            <label htmlFor="soap">Edit SOAP Note:</label>
            <textarea
              id="soap"
              name="soap"
              rows="4"
              cols="50"
              value={editNoteData.visitSoapNote}
              onChange={(e) =>
                setEditNoteData({
                  ...editNoteData,
                  visitSoapNote: e.target.value,
                })
              }
            ></textarea>
            <div className="edit-buttons">
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  saveEditNote();
                }}
                className="cta"
              >
                Save
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowEditModal(false);
                }}
                className="cta-red"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  const saveEditNote = async () => {
    const updateNotePayload = {
      visitSoapNote: editNoteData.visitSoapNote,
      visitId: editNoteData.visitId,
    };
    try {
      await updateSoapNote(updateNotePayload);
      notifySuccess('Patient note updated!');
    } catch (error) {
      notifyError('Error updating patient note');
      throw new Error(error);
    } finally {
      const newHistory = await getUpdatedPatientHistory(
        patient.patientId
      );
      setRibbonItems(newHistory);
      setNotesDetails({});
      // getVisitDetails(updateNotePayload.visitId);
      setEditNoteData(null);
      setShowEditModal(false);
    }
  };

  const renderList = (list, listName) => {
    if (!list) return null;
    try {
      const parsedList = JSON.parse(list);
      if (Array.isArray(parsedList) && parsedList.length) {
        return (
          <small>
            <br />
            {listName && <strong>{listName} </strong>}
            {parsedList.map((item, index) => (
              <span key={index} className="meds">
                {item}
                {index < parsedList.length - 1 && ', '}
              </span>
            ))}
          </small>
        );
      }
    } catch (error) {
      return list;
    }
  };

  const linkify = (text) => {
    if (!text) return null;
    // Split and render text and links
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        // Extract the URL
        const url = part.match(urlRegex)[0];
        // Render the link
        return (
          <a className="wordwrap" key={index} href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      } else {
        // Render the regular text
        return part;
      }
    });
  }

const renderBoldHeadings = (text) => {
  if (!text) return null;
  // Split the text by new lines
  const parts = text.split('\n').map((line, index) => {
    // Check if the line is a heading (separated by multiple new lines and doesn't begin with a dash)
    if (line.trim() !== '' && !line.trim().startsWith('-')) {
      return <span className="aibold" key={index}>{line}</span>;
    } else {
      return line;
    }
  });

  // Combine the parts back, ensuring to keep the line breaks
  return parts.map((part, index) => (
    <Fragment key={index}>
      {/* <br /> */}
      {part}
      <br />
    </Fragment>
  ));
}

  const renderReference = (string) => {
    if (string) {
      return (
        <span>
          <br />
          {linkify(string)}
          <div className="timeline-card-section"></div>
        </span>
      );
    }
  };

  const toggleReferences = (e, bool, hookName) => {
    e.stopPropagation();
    if (bool) {
      hookName(true);
    } else {
      hookName(false);
    }
  };

  const renderSoapNote = (soapNote) => {
    const noteSegments = soapNote.split('\n\n');
    noteSegments.shift();
    const splitNote = noteSegments.flatMap((segment) => {
      return segment.split(
        /(Subjective:|Objective:|Assessment:|Plan:|S:|O:|A:|P:)/
      );
    });

    const renderedSoapNote = splitNote.map((item, index) => {
      if (
        item === 'Subjective:' ||
        item === 'Objective:' ||
        item === 'Assessment:' ||
        item === 'Plan:' ||
        item === 'S:' ||
        item === 'O:' ||
        item === 'A:' ||
        item === 'P:'
      ) {
        return <h4 key={index}>{item}</h4>;
      } else if (item === '') {
        return null;
      } else {
        return (
          <div key={index}>
            {item}
            <div className="timeline-card-section"></div>
          </div>
        );
      }
    });
    return renderedSoapNote;
  };

  const generateRibbonItems = (patientHistory) => {
    return patientHistory.map((item, index) => {
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const appointmentDateTimeUTC = new Date(item.visitDate + ' UTC');
      const appointmentDateTimeLocal = utcToZonedTime(
        appointmentDateTimeUTC,
        localTimezone
      );
      const appointmentDateTimeFormatted = format(
        appointmentDateTimeLocal,
        'MM/dd/yyyy @ h:mm a'
      );
      return (
        <TimelineItem
          key={index}
          dateText={appointmentDateTimeFormatted}
        >
          <div
            className={
              notesDetails.id === item.visitId ? 'card active' : 'card'
            }
            onClick={(e) => {
              getVisitDetails(item.visitId);
            }}
          >
            {visitSummaryIsLoading(item.visitSummary) ? null : (
              <small className="edit" onClick={() => editNote(item)}>
                <ion-icon name="pencil-outline" size="small"></ion-icon>
                &nbsp; EDIT NOTE
              </small>
            )}

            <div>
              {visitSummaryIsLoading(item.visitSummary) ? (
                <h4>Loading...</h4>
              ) : (
                <h4>Summary:</h4>
              )}
              {visitSummaryIsLoading(item.visitSummary) ? (
                <Loader small="true" />
              ) : (
                renderList(item.visitSummary)
              )}
              {notesDetails.id !== item.visitId ? (
                <>
                  <br /> <div className="timeline-card-section"></div>
                </>
              ) : null}
            </div>
            {notesDetails.id === item.visitId ? (
              <div>
                <br />
                <div className="timeline-card-section"></div>

                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {notesDetails.soap && (
                    <small className="edit" onClick={() => copyToClipboard()}>
                      <ion-icon name="copy-outline" size="small"></ion-icon>
                      &nbsp; COPY SOAP NOTE
                    </small>
                  )}
                  {notesDetails.soap && renderSoapNote(notesDetails.soap)}

                  <small
                    className="refs"
                    onClick={(e) =>
                      toggleReferences(e, !showCodes, setShowCodes)
                    }
                  >
                    BILLING CODES
                  </small>
                  {showCodes && (
                    <small
                      className="edit"
                      onClick={() => copyToClipboard(notesDetails.codes)}
                    >
                      <ion-icon name="copy-outline" size="small"></ion-icon>
                      &nbsp; COPY BILLING CODES
                    </small>
                  )}
                  {/* {showCodes && renderReference(notesDetails.codes)} */}
                  {showCodes && (<br />)}
                  {showCodes && renderBoldHeadings(notesDetails.codes)}
                  {!showCodes && <br />}

                  <small
                    className="refs"
                    onClick={(e) =>
                      toggleReferences(e, !showSuperbill, setShowSuperbill)
                    }
                  >
                    SUPER BILL
                  </small>
                  {showSuperbill && (
                    <small
                      className="edit"
                      onClick={() => copyToClipboard(notesDetails.superbill)}
                    >
                      <ion-icon name="copy-outline" size="small"></ion-icon>
                      &nbsp; COPY SUPER BILL
                    </small>
                  )}
                  {showSuperbill && (<br />)}
                  {showSuperbill && renderBoldHeadings(notesDetails.superbill)}
                  {!showSuperbill && <br />}

                  <small
                    className="refs"
                    onClick={(e) =>
                      toggleReferences(e, !showReferences, setShowReferences)
                    }
                  >
                    AI REFERENCE NOTES
                  </small>
                  {showReferences && (
                    <small
                      className="edit"
                      onClick={() => copyToClipboard(notesDetails.reference)}
                    >
                      <ion-icon name="copy-outline" size="small"></ion-icon>
                      &nbsp; COPY AI REFERENCE NOTES
                    </small>
                  )}
                  {showReferences && renderReference(notesDetails.reference)}
                  {!showReferences && <br />}

                  <small
                    className="refs"
                    onClick={(e) =>
                      toggleReferences(
                        e,
                        !showDischargeSummary,
                        setShowDischargeSummary
                      )
                    }
                  >
                    DISCHARGE SUMMARY
                  </small>
                  {showDischargeSummary && (
                    <small
                      className="edit"
                      onClick={() =>
                        copyToClipboard(notesDetails.dischargeSummary)
                      }
                    >
                      <ion-icon name="copy-outline" size="small"></ion-icon>
                      &nbsp; COPY DISCHARGE SUMMARY
                    </small>
                  )}
                  {showDischargeSummary && (<br />)}
                  {showDischargeSummary &&
                    renderBoldHeadings(notesDetails.dischargeSummary)}
                  {!showDischargeSummary && <br />}

                  <small
                    className="refs"
                    onClick={(e) =>
                      toggleReferences(
                        e,
                        !showDischargeInstructions,
                        setShowDischargeInstructions
                      )
                    }
                  >
                    DISCHARGE INSTRUCTIONS
                  </small>
                  {showDischargeInstructions && (
                    <small
                      className="edit"
                      onClick={() =>
                        copyToClipboard(notesDetails.dischargeInstructions)
                      }
                    >
                      <ion-icon name="copy-outline" size="small"></ion-icon>
                      &nbsp; COPY DISCHARGE INSTRUCTIONS
                    </small>
                  )}
                  {showDischargeInstructions && (<br />)}
                  {showDischargeInstructions &&
                    renderBoldHeadings(notesDetails.dischargeInstructions)}
                </div>
              </div>
            ) : null}
            <small>
              Note Saved by {item.visitDoctor} on {appointmentDateTimeFormatted}
            </small>
          </div>
        </TimelineItem>
      );
    });
  };

  const renderPatientInfo = () => {
    // hack b/c api is sending only first 2 characters for w/e reason.
    const displayGender = (gender) => {
      const genderLowerCase = gender.toLowerCase();
      if (genderLowerCase === 'ma' || genderLowerCase === 'm') {
        return 'Male';
      } else if (genderLowerCase === 'fe' || genderLowerCase === 'f') {
        return 'Female';
      } else {
        return gender;
      }
    };

    const displayAllergies = (patientAllergies) => {
      const allergies = patientAllergies.toUpperCase();
      if (allergies === 'N/A') {
        return 'NONE';
      } else if (allergies === '[]') {
        return 'NONE';
      } else {
        return allergies;
      }
    };

    return (
      <div className="patient-panel">
        {/* <img
          className="profilepic"
          src="http://placekitten.com/64/64"
          alt="patient"
        /> */}
        <h1>{`${patient.patientFname} ${patient.patientLname}`}</h1>
        <span>Age: {patient.patientAge || '35'} - </span>
        <span>Sex: {displayGender(patient.patientGender)} </span>
        <br />
        <small className="allergies">
          ALLERGIES: {displayAllergies(patient.patientAllergies)}
        </small>
      </div>
    );
  };

  return !patient || !ribbonItems ? null : (
    <div className="patients">
      {renderPatientInfo()}
      <div className="timeline-panel" ref={timelineRef}>
        {showEditModal ? editModal() : (
          <SpeechToText
            patient={patient}
            editNoteData={editNoteData}
            getDetailsCallback={editCallback}
            callback={addItemToRibbon}
          />
        )}
        {!ribbonItems.length ? (
          <h2>No Patient History</h2>
        ) : (
          <Timeline lineColor="#4959ae">
            {generateRibbonItems(ribbonItems)}
          </Timeline>
        )}
      </div>
    </div>
  );
}
