import './Loader.css'

export default function Loader({small}) {
  return(

    <div className={small ? 'loader-container-small' : 'loader-container'}>
      <div className="loader-panel">
        <div className="spinner">
          <div className="bar-outer right top b1">
            <div className="bar"></div>
          </div>
          <div className="bar-outer down-left b2">
            <div className="bar"></div>
          </div>
          <div className="bar-outer down-right b3">
            <div className="bar"></div>
          </div>
          <div className="bar-outer down-left b4">
            <div className="bar"></div>
          </div>
          <div className="bar-outer right bottom b5">
            <div className="bar"></div>
          </div>
          <div className="bar-outer up-left b6">
            <div className="bar"></div>
          </div>
          <div className="bar-outer up-right b7">
            <div className="bar"></div>
          </div>
          <div className="bar-outer up-left b8">
            <div className="bar"></div>
          </div>
        </div>
      </div>
    </div>

  );
}
