import { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/Context';
import Footer from '../components/Footer/Footer';
import Menu from '../components/Menu/Menu';

const TopBar = ({ user }) => {
  const navigate = useNavigate();
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();

  const logout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      auth.logout(() => navigate('/signup', { replace: true }));
    }
  };

  return (
    <div id="topbar">
      <span>
        Welcome
        {` ${(user && user.userFirstName) || null}
          ${(user && user.userLastName) || null
        }!`}{' '}
      </span>
      <div className="mobile-menu">
        <span onClick={() => navigate('/patients')}>Patients</span>
        <span onClick={() => navigate('/feedback')}>Feedback</span>
        <span onClick={logout}>Logout</span>
      </div>
      <div className="logo">
        ribb
        <ion-icon class="ribbon" name="ribbon-sharp" size="small"></ion-icon>n
      </div>
    </div>
  );
};

export default function Root() {
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();
  const user = auth.getUser();

  return (
    <>
      <div id="root-container">
        <>
          <TopBar user={user} />
          <div id="main-container">
            <Menu />
            <Outlet />
          </div>
          <Footer />
        </>
      </div>
    </>
  );
}