import './Timeline.css';

const Timeline = ({
  animate = true,
  children,
  className = '',
  lineColor = '#000',
}) => (
  <div className="timeline--wrapper">
    <div
      className={`timeline ${className} ${animate ? 'timeline--animate' : ''}`}
      style={{ color: `${lineColor}` }}
    >
      {children}
    </div>
  </div>
);

export default Timeline;
