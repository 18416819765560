import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import './TimelineItem.css';

const TimelineItem = ({
  id = '',
  children = '',
  dateText = '',
  dateStyle = null,
  dateComponent = null,
  dateInnerStyle = null,
  bodyContainerStyle = null,
  style = null,
  className = '',
  visibilitySensorProps = { partialVisibility: true, offset: { bottom: 50 } },
}) => {
  const [visible, setVisible] = useState(false);

  const onVisibilitySensorChange = (isVisible) => {
    if (isVisible) {
      setVisible(true);
    }
  };

  return (
    <div
      id={id}
      className={`entry ${className} ${
        children === '' ? 'timeline-item--no-children' : ''
      }`}
      style={style}
    >
      <VisibilitySensor
        {...visibilitySensorProps}
        onChange={onVisibilitySensorChange}
      >
        <>
          <div className="title">
            <div className={`${visible ? 'bounce-in' : 'is-hidden'}`}>
              {dateComponent !== null ? (
                dateComponent
              ) : (
                <span style={dateStyle} className="timeline-item-date">
                  <time
                    style={dateInnerStyle}
                    className="timeline-item-dateinner"
                    title={dateText}
                  >
                    {dateText}
                  </time>
                </span>
              )}
            </div>
          </div>
          <div className="body">
            <div
              className={`body-container ${
                visible ? 'bounce-in' : 'is-hidden'
              }`}
              style={bodyContainerStyle}
            >
              {children}
            </div>
          </div>
        </>
      </VisibilitySensor>
    </div>
  );
};

export default TimelineItem;
