import { useContext, useState } from 'react';
import { AuthContext } from '../utils/Context';

export default function Hello() {
  const [isHover, setIsHover] = useState(false);
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();
  const user = auth.getUser();

  return (
    <div className="content">
      <div className="robo-container">
        {isHover && (
          <h1 className="robot-greeting">Hello {user.userFirstName} {user.userLastName}!</h1>
        )}
        {
          !isHover && (
            <span className="robot-whisper"></span>
          )
        }
        <div
          className="cute-robot-v1"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="circle-bg">
            <div className="robot-ear left"></div>
            <div className="robot-head">
              <div className="robot-face">
                <div className="eyes left"></div>
                <div className="eyes right"></div>
                <div className="mouth"></div>
              </div>
            </div>
            <div className="robot-ear right"></div>
            <div className="robot-body"></div>
          </div>
        </div>
          {isHover && (
            <h1 className="robot-message">
              Let's have a great day and help some patients!
            </h1>
          )}
      </div>
    </div>
  );
}
