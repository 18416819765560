import { AuthContext } from './Context';
import axios from 'axios';

const baseURL =
  process.env.REACT_APP_IS_PROD === 'true'
    ? 'https://ribbon-api-prod.azurewebsites.net/api'
    : 'https://ribbon-api-dev.azurewebsites.net/api';

// singleton without direct access from outside the module.
const authStore = {
  signup: async (user) => {
    const url = `${baseURL}/account`;
    const payload = {
      userEmail: user.email,
      userPassword: user.password,
      userFirstName: user.firstName,
      userLastName: user.lastName,
      userPhone: user.phone,
      userTypeId: user.usertype_id,
    };
    try {
      const response = await axios.post(url, payload);
      const user = response.data;
      const token = response.data.userToken;
      delete user['userToken'];

      sessionStorage.setItem('token', token);
      sessionStorage.setItem('user', JSON.stringify(user));

      authStore.token = token;
    } catch (error) {
      console.error('Signup Error:', error);
      return Promise.reject(error);
    }
  },

  login: async (email, password) => {
    const url = `${baseURL}/Authenticate/login`;
    const payload = {
      userEmail: email,
      userPassword: password,
    };
    try {
      const response = await axios.post(url, payload);
      const user = response.data;
      const token = response.data.userToken;
      delete user['userToken'];

      sessionStorage.setItem('token', token);
      sessionStorage.setItem('user', JSON.stringify(user));

      authStore.token = token;
    } catch (error) {
      console.error('Login Error:', error);
      return Promise.reject(error);
    }
  },

  forgotPassword: async (email) => {
    const url = `${baseURL}/Account/forgot`;
    const payload = {
      userEmail: email,
    };
    try {
      const response = await axios.post(url, payload);
      return response;
    } catch (error) {
      console.error('Forgot Password Error:', error);
      return Promise.reject(error);
    }
  },

  resetPassword: async (password, token) => {
    const url = `${baseURL}/Account/resetpassword`;
    const payload = {
      password,
      emailToken: token,
    };
    try {
      const response = await axios.post(url, payload);
      return response;
    } catch (error) {
      console.error('Validate Email Token Error:', error);
      return Promise.reject(error);
    }
  },

  signout(callback) {
    callback();
  },
};

export default function AuthProvider({ children }) {
  const getToken = () => {
    const token = sessionStorage.getItem('token') || null;
    return token;
  }

  const getUser = () => {
    const user = sessionStorage.getItem('user') || null;
    return JSON.parse(user);
  };

  const updateUser = (user) => {
    sessionStorage.setItem('user', JSON.stringify(user));
  };

  const signup = (user) => {
    return authStore.signup(user);
  };

  const login = (email, password) => {
    return authStore.login(email, password);
  };

  const logout = (callback) => {
    return authStore.signout(() => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');
      setTimeout(callback, 100);
    });
  };

  const forgotPassword = (email) => {
    return authStore.forgotPassword(email);
  };

  const resetPassword = (password, token) => {
    return authStore.resetPassword(password, token);
  }

  const value = {
    getUser,
    getToken,
    updateUser,
    signup,
    login,
    logout,
    forgotPassword,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
