import { useContext, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/Context';


export default function Billing() {
  const navigate = useNavigate();
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();
  const user = auth.getUser();

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const billingLevel = searchParams.get('billing_level');
  const amount = searchParams.get('amount');

  useEffect(() => {
    if (!sessionId) return;
    const newStatus = billingLevel === 'Monthly' ? 1 : 2;
    const newUser = { ...user, practice: { ...user.practice, practicePlanId: newStatus }, payment: 1 };
    auth.updateUser(newUser);
  }, [sessionId, auth, user, billingLevel]);

  return (
    <div className="content">
      {sessionId ? (
        <div className="billing">
          <div className="heading">
            <h1>Welcome to Ribbon!</h1>
            <br />
            <br />
            <h3>
              Your subscription is confirmed. You will be billed {'$'}
              {amount} {billingLevel === 'Monthly' ? 'monthly' : 'annually'}.
            </h3>
            <p>
              Please note: <span className="emphasize">RibbonMD, Inc.</span>{' '}
              will appear on your credit card statement for your subscription.
            </p>
            <p>
              Thank you for choosing Ribbon for your healthcare documentation
              needs. We're excited to have you on board and look forward to
              supporting your practice with our AI-driven solutions.
            </p>
            <p>
              <span className="emphasize">Ready to get started?</span>
              <br />
              Your journey towards streamlined and efficient healthcare
              documentation begins now. Click the button below to dive into the
              Ribbon platform and explore all the features waiting for you.
            </p>
            <button className="cta" onClick={() => navigate('/hello')}>
              GET STARTED
            </button>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      ) : (
        <div className="billing">
          <div className="heading">
            <h1>Ribbon Subscription Plan</h1>
            <h3>
              Unleash the Full Potential of AI in Healthcare Documentation
            </h3>
            <p>
              Ribbon brings the power of AI to your practice, simplifying and
              enhancing medical documentation like never before. Our
              comprehensive subscription plan is designed to cater to all your
              documentation needs, regardless of your practice's size.
              Experience the future of healthcare documentation today.
            </p>
          </div>
          {/* test mode */}
          {/* <stripe-pricing-table
            pricing-table-id="prctbl_1OCmrdDpn1a82IbgBQMxxka7"
            publishable-key="pk_test_51OC6jTDpn1a82IbgQHt1LCveW7gwku3iyq8SRMKhOiFKICVW4by3VNZ3LupyDJo8n6D4FIAUWOsZUtEP2NvvzPao003mxP2UlS"
            customer-email={user.userEmail}
            client-reference-id={user.userId}
          ></stripe-pricing-table> */}
          {/* prod mode */}
          <stripe-pricing-table
            pricing-table-id="prctbl_1OMfRWDpn1a82IbgjbbtJDgF"
            publishable-key="pk_live_51OC6jTDpn1a82Ibgae82deNZlYa7PIn6H8KtSgdWxPhIrihwhWH8C55zrfzY6J4kUzRAc40s8HRqnTfr712PPnWJ00D9R06xaz"
          ></stripe-pricing-table>
          <br />
          <div className="heading">
            <p>
              <span className="emphasize">Questions/Need Help?</span>
              <br />
              If you have any questions or need help getting started, our sales
              team is here for you.
              <br />
              We also provide bulk user discounts. If you have a large practice and
              would like to learn more about our pricing options, please {''}
              <a href="mailto:shiv@ribbonmd.com?subject=Subscription Help">
                Contact Us
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
