import { useContext } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AuthContext } from '../../utils/Context';
import './Menu.css';

export default function Menu() {
  const navigate = useNavigate();
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();

  const logout = () => {
    auth.logout(() => navigate('/signup', { replace: true }));
  };

  const confirmLogout = () => {
    confirmAlert({
      title: 'Logout',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => logout(),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div id="menu">
      <NavLink
        to="calendar"
        className={({ isActive, isPending }) =>
          isPending ? 'pending' : isActive ? 'active-menu-item' : ''
        }
      >
        <ion-icon name="calendar-sharp" size="large"></ion-icon>
        Calendar
      </NavLink>

      <NavLink
        to="patients"
        className={({ isActive, isPending }) =>
          isPending ? 'pending' : isActive ? 'active-menu-item' : ''
        }
      >
        <ion-icon name="people-sharp" size="large"></ion-icon>
        Patients
      </NavLink>

      <NavLink
        to="feedback"
        className={({ isActive, isPending }) =>
          isPending ? 'pending' : isActive ? 'active-menu-item' : ''
        }
      >
        <ion-icon name="create-sharp" size="large"></ion-icon>
        Feedback
      </NavLink>

      <NavLink
        to="settings"
        className={({ isActive, isPending }) =>
          isPending ? 'pending' : isActive ? 'active-menu-item' : ''
        }
      >
        <ion-icon name="settings-sharp" size="large"></ion-icon>
        Settings
      </NavLink>

      <NavLink onClick={confirmLogout}>
        <ion-icon name="log-out-sharp" size="large"></ion-icon>
        Logout
      </NavLink>
    </div>
  );
}
