import { useState } from 'react';
import { addPatient } from '../../utils/API'
import { notifyError, notifySuccess } from '../../utils/Toast';
import './NewPatient.css';

const intialPatientState = {
  patientFname: '',
  patientLname: '',
  patientAge: 0,
  patientGender: '',
  patientAllergies: '',
}

export default function NewPatient({hideNewPatient, updatePatientList}) {
  const [patientData, setPatientData] = useState(intialPatientState);

  const handleAddNewPatient = async (event) => {
    event.preventDefault();
    try {
      await addPatient(patientData);
      notifySuccess('Patient added successfully!');
      updatePatientList();
    } catch (error) {
      notifyError('Error adding new patient');
      throw new Error(error);
    }
    hideNewPatient(false);
  }

  const updatePatientData = (key, value) => {
    setPatientData({
      ...patientData,
      [key]: value,
    });
  };

  return (
    <div className="content-col">
      <div className="new-patient-header">
        <h1>Add New Patient</h1>
        {/* <pre>{JSON.stringify(patientData, null, 2) || null}</pre> */}
        {/* <p>
          This Trial version of Ribbon is for testing purposes only. Please use fictional
          or test data to ensure patient privacy and confidentiality.
        </p> */}
      </div>
      <form className="new-patient-form">
        <div>
          <label htmlFor="patientFname">First Name</label>
          <input
            type="text"
            className="text"
            id="patientFname"
            name="patientFname"
            onChange={(e) => updatePatientData('patientFname', e.target.value)}
          />
          <label htmlFor="patientAge">Age</label>
          <input
            type="number"
            className="text"
            id="patientAge"
            name="patientAge"
            onChange={(e) => updatePatientData('patientAge', e.target.value)}
          />
          <label htmlFor="patientAllergies">Patient Allergies</label>
          <input
            type="text"
            className="text"
            id="patientAllergies"
            name="patientAllergies"
            onChange={(e) =>
              updatePatientData('patientAllergies', e.target.value)
            }
          />
          <label htmlFor="gender">Primary Insurance Provider</label>
          <input
            type="text"
            className="text"
            id="insuranceProvider"
            name="insuranceProvider"
          />
          <label htmlFor="gender">Primary Insurance Group Id #</label>
          <input type="text" className="text" id="groupId" name="groupId" />
          <label htmlFor="gender">Primary Insurance Phone #</label>
          <input
            type="text"
            className="text"
            id="insurancePhone"
            name="insurancePhone"
          />

          <label htmlFor="gender">Secondary Insurance Provider</label>
          <input
            type="text"
            className="text"
            id="insuranceProvider"
            name="insuranceProvider"
          />
          <label htmlFor="gender">Secondary Insurance Group Id #</label>
          <input type="text" className="text" id="groupId" name="groupId" />
          <label htmlFor="gender">Secondary Insurance Phone #</label>
          <input
            type="text"
            className="text"
            id="insurancePhone"
            name="insurancePhone"
          />
          <label htmlFor="gender">Secondary Insurance Subscriber DOB</label>
          <input
            type="text"
            className="text"
            id="insurancePhone"
            name="insurancePhone"
          />
        </div>

        <div>
          <label htmlFor="patientLname">Last Name</label>
          <input
            type="text"
            className="text"
            id="patientLname"
            name="patientLname"
            onChange={(e) => updatePatientData('patientLname', e.target.value)}
          />
          <label htmlFor="gender">Gender</label>
          <input
            type="text"
            className="text"
            id="patientGender"
            name="patientGender"
            onChange={(e) => updatePatientData('patientGender', e.target.value)}
          />
          <label htmlFor="gender">Primary Insurance Type</label>
          <input
            type="text"
            className="text"
            id="insuranceProvider"
            name="insuranceProvider"
            placeholder="PPO, HMO, etc."
          />
          <label htmlFor="gender">Primary Insurance Member Id #</label>
          <input type="text" className="text" id="memberId" name="memberId" />
          <label htmlFor="gender">Primary Insurance Subscriber Name</label>
          <input
            type="text"
            className="text"
            id="insurancePhone"
            name="insurancePhone"
          />
          <label htmlFor="gender">Primary Insurance Subscriber DOB</label>
          <input
            type="text"
            className="text"
            id="insurancePhone"
            name="insurancePhone"
          />

          <label htmlFor="gender">Secondary Insurance Type</label>
          <input
            type="text"
            className="text"
            id="insuranceProvider"
            name="insuranceProvider"
            placeholder="PPO, HMO, etc."
          />
          <label htmlFor="gender">Secondary Insurance Member Id #</label>
          <input type="text" className="text" id="memberId" name="memberId" />
          <label htmlFor="gender">Secondary Insurance Subscriber Name</label>
          <input
            type="text"
            className="text"
            id="insurancePhone"
            name="insurancePhone"
          />
        </div>
      </form>
      <div className="new-patient-actions">
        <button className="cta-red" onClick={() => hideNewPatient(false)}>
          Cancel
        </button>
        <button
          disabled={
            !patientData.patientAge ||
            !patientData.patientFname ||
            !patientData.patientLname ||
            !patientData.patientGender
          }
          className={
            !patientData.patientAge ||
            !patientData.patientFname ||
            !patientData.patientLname ||
            !patientData.patientGender
              ? 'disabled'
              : 'cta'
          }
          onClick={handleAddNewPatient}
        >
          Save Patient
        </button>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
