import { createContext } from 'react';

const AuthContext = createContext({
  getUser: () => {},
  getToken: () => {},
  updateUser: () => {},
  signup: () => {},
  login: () => {},
  logout: () => {},
  forgotPassword: () => {},
  resetPassword: () => {},
});

const LoadingContext = createContext({
  isLoading: false,
  setIsLoading: null,
});

export { AuthContext, LoadingContext }