import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { registerLicense } from '@syncfusion/ej2-base';

import Root from './pages/Root';
import Patient from './pages/Patients';
import Signup from './pages/Signup';
import Hello from './pages/Hello';
import Feedback from './pages/Feedback';
import Billing from './pages/Billing';
import Settings from './pages/Settings';
import Reset from './pages/Reset';
import Calendar from './pages/Calendar';

import ErrorPage from './components/ErrorPage';
import RequireAuth from './components/RequireAuth';
import Loader from './components/Loader/Loader';

import AuthProvider from './utils/AuthProvider';
import LoadingProvider from './utils/LoadingProvider';
import { getPatients, getProviders, getAppointments } from './utils/API';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

// import reportWebVitals from './reportWebVitals';

registerLicense(
  'ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Sd0FiUX5ddHJVTmJe'
);

Bugsnag.start({
  apiKey: 'd55381296b44e6cca800ae8b423a4a12',
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: 'd55381296b44e6cca800ae8b423a4a12' });

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

// isLoading, loadRouteData, routeDataLoadingWrapper are
// used to load data for a route before rendering it.
// hacky cause react-router? gotta find a better way to do this
let isLoading = false;

const routeDataLoadingWrapper = (element) => {
  if (isLoading) return <Loader />;
  return element;
}

const loadRouteData = (apiFunction) => {
  return async () => {
    isLoading = true;
    try {
      const data = await apiFunction();
      isLoading = false;
      return data;
    } catch (error) {
      isLoading = false;
      throw new Error(error);
    }
  }
}

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <Root />
        <ToastContainer />
      </RequireAuth>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'patients/',
        element: routeDataLoadingWrapper(<Patient/>),
        loader: async () => {
          const patients = await loadRouteData(getPatients)();
          return { patients };
        }
      },
      {
        path: 'calendar',
        element: routeDataLoadingWrapper(<Calendar />),
        loader: async () => {
          const providers = await loadRouteData(getProviders)();
          const patients = await loadRouteData(getPatients)();
          const appointmentData = await loadRouteData(getAppointments)();
          return { providers, patients, appointmentData };
        }
      },
      {
        path: 'hello',
        element: <Hello />,
      },
      {
        path: 'feedback',
        element: <Feedback />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
    ],
  },
  {
    path: '/signup',
    element: <Signup />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/billing',
    element: <Billing />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/reset',
    element: <Reset />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <LoadingProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </LoadingProvider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
