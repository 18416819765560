import { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../utils/Context';
import { emailRegex } from '../utils/regex';
import { useLoading } from '../utils/Hooks';
import Loader from '../components/Loader/Loader';
import Footer from '../components/Footer/Footer';

export default function Reset() {
  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [password2, setPassword2] = useState('');
  const [passwordIsValid2, setPasswordIsValid2] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { isLoading, setIsLoading } = useLoading();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const navigate = useNavigate();
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();

  const updateEmail = (email) => {
    setEmail(email);
    if (emailRegex.test(email)) {
      setEmailIsValid(true);
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid email.');
      setEmailIsValid(false);
    }
  };

  const updatePassword = (password) => {
    setPassword(password);
    if (password.length < 8) {
      setErrorMessage('New password must be at least 8 characters.');
      setPasswordIsValid(false);
    } else {
      setErrorMessage('');
      setPasswordIsValid(true);
    }
  };


  const updatePassword2 = (password2) => {
    setPassword2(password2);
    if (password2.length < 8) {
      setErrorMessage('New password must be at least 8 characters.');
      setPasswordIsValid2(false);
    } else if (password !== password2) {
      setErrorMessage('Passwords must match.');
      setPasswordIsValid2(false);
    } else {
      setErrorMessage('');
      setPasswordIsValid2(true);
    }
  };


  const handleReset = async () => {
    if (!token) {
      setErrorMessage('Password reset token is missing. Please try resetting your password again.');
      return;
    }

    // return;
    setIsLoading(true);
    try {
      const res = await auth.resetPassword(password, token);
      setIsLoading(false);
      if (res.data === 'expired') {
        setErrorMessage('Password reset token has expired. Please try resetting your password again. You will be redirected to re enter your email in 10 seconds.');
        setTimeout(() => {
          setErrorMessage('');
          navigate('/reset', { replace: true });
        }, 10000);
        return;
      }
      setErrorMessage(
        'Password reset successfully. You will be redirected to the log in page in 10 seconds.'
      );
      setTimeout(() => {
        setErrorMessage('');
        navigate('/signup', { replace: true });
      }, 10000);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  const handleForgotPassword = async () => {
    setIsLoading(true);
    try {
      await auth.forgotPassword(email);
      setIsLoading(false);
      setErrorMessage(
        `If a user for ${email} exists, you will receive a password reset email.`
      );
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  }

  const renderEmailForm = () => {
    return (
      <>
        <form className="reset-form">
          <input
            className="text"
            type="email"
            name="email"
            id="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => updateEmail(e.target.value)}
            pattern=".+@globex\.com"
            required
            autoComplete="email"
          />
        </form>
        <button
          className={!email || !emailIsValid ? 'disabled' : 'cta'}
          disabled={!emailIsValid}
          onClick={() => handleForgotPassword()}
        >
          Send Reset Password Email
        </button>
        <div className={!errorMessage ? 'form-error-hidden' : 'form-error'}>
          {errorMessage}
        </div>
      </>
    );
  };

  const renderNewPasswordForm = () => {
    return (
      <>
        <form className="reset-form">
          <input
            className="text"
            type="password"
            name="password"
            id="password"
            placeholder="Enter new password"
            value={password}
            onChange={(e) => updatePassword(e.target.value)}
            pattern=".+@globex\.com"
            required
          />
          <input
            className="text"
            type="password"
            name="password2"
            id="password2"
            placeholder="Enter new password again"
            value={password2}
            onChange={(e) => updatePassword2(e.target.value)}
            pattern=".+@globex\.com"
            required
          />
        </form>
        <button
          className={!password || !passwordIsValid || !passwordIsValid2 ? 'disabled' : 'cta'}
          disabled={!passwordIsValid || !passwordIsValid2}
          onClick={() => handleReset()}
        >
          Reset Password
        </button>
        <div className={!errorMessage ? 'form-error-hidden' : 'form-error'}>
          {errorMessage}
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div id="reset">
          <div className="trill">
            ribb
            <ion-icon
              class="signup-logo"
              name="ribbon-sharp"
              size="large"
            ></ion-icon>
            n
          </div>
          <h2>Reset Password</h2>
          {
            token
            ? renderNewPasswordForm()
            : renderEmailForm()
          }
          <br />
          <br />
          <br />
          <br />
          <Footer />
        </div>
      )}
    </>
  );
}
