import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/Context';

export default function Settings() {
  const navigate = useNavigate();
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();

  const changePassword = () => {
    auth.logout(() => navigate('/reset', { replace: true }));
  };

  return (
    <div className="content">
      <div className="details">
        <div className="settings">
          <div>
            <h1>Settings</h1>
            <br />
            <br />
            <button className="cta" onClick={changePassword}>Change Password</button>
            <br />
            <br />
            <a
              href="https://billing.stripe.com/p/login/fZedTBc65gug5tS144"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="cta">Manage Subscription</button>
            </a>
            <br />
            <br />
            <span>
              You can update your billing information or cancel your account
              using the button above.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
